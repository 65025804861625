import { useEffect, useMemo, useRef, useState } from 'react';

import VirtualBackgroundExtension from 'agora-extension-virtual-background';
import AgoraRTC, {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import { useInterval } from 'usehooks-ts';

import { useAgoraEngine } from './useAgoraEngine';

const APP_ID = '090dcb2994404f6aa1d5ba3f40f39c71';

let oldChannel: string | null = null;
export const uid = Math.floor(Math.random() * 1000000000);
console.log('Agora uid: ' + uid);

let localAudioTrack: IMicrophoneAudioTrack | null = null;
let localVideoTrack: ICameraVideoTrack | null = null;

let processor: any | null = null;
const extension = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([extension]);
if (!extension.checkCompatibility()) {
  console.error('Does not support Virtual Background!');
}

let _cameras: MediaDeviceInfo[] | null = null;
const getCameras = async () => {
  if (!_cameras) {
    _cameras = await AgoraRTC.getCameras();
  }
  return _cameras;
};

// TODO: switch microphone
let _microphones: MediaDeviceInfo[] | null = null;
const getMicrophones = async () => {
  if (!_microphones) {
    _microphones = await AgoraRTC.getMicrophones();
  }
  return _microphones;
};

export const useAgoraSend = (
  channel: string,
  tokenFunc: (uid: number) => Promise<string>,
) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [joined, setJoined] = useState(false);
  const [joining, setJoining] = useState(false);
  const [cameraOnProgress, setCameraOnProgress] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]);
  const [microphoneList, setMicrophoneList] = useState<MediaDeviceInfo[]>([]);
  const [activeCameraIndex, setActiveCameraIndex] = useState(0);
  const [activeCameraDeviceId, setActiveCameraDeviceId] = useState<
    string | null
  >(null);
  const [activeMicrophoneDeviceId, setActiveMicrophoneDeviceId] = useState<
    string | null
  >(null);
  const supported = useMemo(() => {
    return AgoraRTC.checkSystemRequirements();
  }, []);

  const engine = useAgoraEngine(tokenFunc, () => {
    startLive();
  });

  const cameraOn = async () => {
    if (cameraOnProgress) {
      return;
    }
    setCameraOnProgress(true);
    console.log('camera on');
    try {
      const microphones = await getMicrophones();
      setMicrophoneList(microphones);
      localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: microphones[0].deviceId,
        encoderConfig: 'high_quality_stereo',
        ANS: false,
        AEC: false,
      });
      const cameras = await getCameras();
      setCameraList(cameras);
      localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: cameras[activeCameraIndex].deviceId,
        encoderConfig: '1080p_1',
      });
    } catch (e) {
      if (e instanceof Error && e.message.includes('NotAllowedError')) {
        setPermissionDenied(true);
        return;
      }
      throw e;
    }

    if (!divRef.current) {
      throw new Error('divRef.current is null');
    }
    localVideoTrack.play(divRef.current, { fit: 'contain', mirror: false });
    setVideoStarted(true);
    setCameraOnProgress(false);
  };

  async function getProcessorInstance() {
    if (!processor && localVideoTrack) {
      processor = extension.createProcessor();
      try {
        await processor.init('./assets/wasms');
      } catch (e) {
        console.log('Fail to load WASM resource!');
        return null;
      }
      localVideoTrack
        .pipe(processor)
        .pipe(localVideoTrack.processorDestination);
    }
    return processor;
  }

  const setVirtualImage = async (image: string) => {
    console.log(typeof image);
    const imgElement = document.createElement('img');
    imgElement.onload = async () => {
      let processor = await getProcessorInstance();
      processor.setOptions({ type: 'img', source: imgElement });
      await processor.enable();
    };
    imgElement.src = image;
  };

  const virtualImageOff = async () => {
    let processor = await getProcessorInstance();
    await processor.disable();
  };

  const startLive = async () => {
    setJoining(true);

    const token = await tokenFunc(uid);
    await engine.join(APP_ID, channel, token, uid);

    const cameras = await getCameras();
    try {
      const microphones = await getMicrophones();
      console.log({ microphones });
      if (!localAudioTrack) {
        localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: microphones[0].deviceId,
          encoderConfig: 'high_quality_stereo',
          ANS: false,
          AEC: false,
        });
      }
      if (!localVideoTrack) {
        localVideoTrack = await AgoraRTC.createCameraVideoTrack({
          cameraId: cameras[activeCameraIndex].deviceId,
          encoderConfig: '1080p_1',
        });
        if (!divRef.current) {
          throw new Error('divRef.current is null');
        }
        localVideoTrack.play(divRef.current, { fit: 'contain', mirror: false });
      }
    } catch (e: unknown) {
      if (e instanceof Error && e.message.includes('NotAllowedError')) {
        setPermissionDenied(true);
        console.error('Permission denied error!!!');
        return;
      }
      throw e;
    }

    await engine.publish([localAudioTrack, localVideoTrack]);

    setJoining(false);
    setJoined(true);
  };

  const stopLive = async () => {
    setLeaving(true);

    await engine.leave();

    setLeaving(false);
    setJoined(false);
  };

  const switchCameraNext = async () => {
    if (!localVideoTrack) {
      await cameraOn();
      return;
    }

    const cameras = await getCameras();
    if (cameras.length < 2) {
      return;
    }

    const nextCameraIndex = (activeCameraIndex + 1) % cameras.length;
    await localVideoTrack.setDevice(cameras[nextCameraIndex].deviceId);
    setActiveCameraIndex(nextCameraIndex);
    setActiveCameraDeviceId(cameras[nextCameraIndex].deviceId);
  };

  const switchCameraTo = async (deviceId: string) => {
    if (!localVideoTrack) {
      await cameraOn();
      return;
    }

    await localVideoTrack.setDevice(deviceId);
    setActiveCameraIndex(
      cameraList.findIndex((camera) => camera.deviceId === deviceId),
    );
    setActiveCameraDeviceId(deviceId);
  };

  const switchMicrophoneTo = async (deviceId: string) => {
    if (!localAudioTrack) {
      await cameraOn();
      return;
    }

    await localAudioTrack.setDevice(deviceId);
    setActiveMicrophoneDeviceId(deviceId);
  };

  if (channel !== oldChannel) {
    oldChannel = channel;
    if (joined) {
      stopLive();
    }
  }

  return {
    divRef,
    cameraOn,
    startLive,
    stopLive,
    activeCameraIndex,
    activeCameraDeviceId,
    switchCameraNext,
    switchCameraTo,
    activeMicrophoneDeviceId,
    switchMicrophoneTo,
    cameraList,
    microphoneList,
    setVirtualImage,
    virtualImageOff,
    permissionDenied,
    supported,
    joined,
    joining,
    leaving,
    videoStarted,
    cameraOnProgress,
  };
};

export const useAudioIsActive = (interval?: number) => {
  const [active, setActive] = useState(false);

  const check = async () => {
    if (!localAudioTrack) {
      setActive(false);
      return;
    }
    const ok = await AgoraRTC.checkAudioTrackIsActive(localAudioTrack);
    setActive(ok);
  };

  useEffect(() => {
    check();
  }, []);
  useInterval(check, interval || 1000);

  return active;
};
