import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import useSWR, { useSWRConfig } from 'swr';

import { EventLogItem } from '../EventLog';
import { LogoBackground } from '../LogoBackground';
import { SERVICE_TYPE } from '../SERVICE_TYPE';
import { Countdown } from '../admin/AdminEventRealtime';
import { api } from '../api';
import { getRandomRgbString } from '../color';
import { Logo } from '../components/Logo';
import { useFrames } from '../frames';
import { LiveReceive } from '../live/LiveReceive';
import { Ceremony, CeremonyMedia } from '../types';
import { CeremonyParticipantInfo } from './CeremonyParticipantInfo';
import { CeremonyParticipantJoin } from './CeremonyParticipantJoin';
import { ChatSend } from './ChatSend';
import {
  ParticipantAnnouncePost,
  ParticipantAnnouncePre,
} from './EventParticipantAnnounce';
import { ParticipantPanel } from './ParticipantPanel';
import { PaymentPanel } from './PaymentPanel';
import { Video } from './Video';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useIsShort = () => {
  const THRESHOLD = 500;
  const [isShort, setIsShort] = useState(false);
  useEffect(() => {
    if (window.visualViewport) {
      const onResize = () => {
        setIsShort(
          (window.visualViewport?.height ?? window.innerHeight) < THRESHOLD,
        );
      };
      window.visualViewport.addEventListener('resize', onResize);
      onResize();
      return () => {
        window.visualViewport?.removeEventListener('resize', onResize);
      };
    } else {
      const onResize = () => {
        setIsShort(window.innerHeight < THRESHOLD);
      };
      window.addEventListener('resize', onResize);
      onResize();
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, []);
  return isShort;
};

export const CeremonyParticipantPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  return <CeremonyParticipantContent token={token} />;
};

export const CeremonyParticipantContent: React.FC<{
  token: string | undefined;
}> = ({ token }) => {
  const { data: media } = useSWR<CeremonyMedia>(
    `/api/ceremony/${token}/media/`,
    fetcher,
  );
  const { data: ceremony, error } = useSWR<Ceremony>(
    `/api/ceremony/${token}/`,
    fetcher,
    { refreshInterval: 3 },
  );
  const { mutate } = useSWRConfig();

  const [name, setName] = useState(
    localStorage.getItem(`ceremony:${token}:name`) || '',
  );
  const paid = !!localStorage.getItem(`ceremony:${token}:paid`);

  const location = useLocation();
  const search = location.search;
  const urlQuery = new URLSearchParams(search);
  console.log(urlQuery.get('paid'));

  const frames = useFrames();

  const scrollToBottomRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    // fixme: とても不安定な挙動になりそうな予感がする。とりあえずworkaroundしたので、いつか直したい。
    window.requestAnimationFrame(() => {
      setTimeout(() => {
        scrollToBottomRef.current?.scrollIntoView();
        console.log('scroll to bottom');
      }, 100);
    });
  }, [ceremony?.logs?.length]);

  if (urlQuery.get('paid') != null) {
    // paid
    localStorage.setItem(`ceremony:${token}:paid`, 'true');
    window.location.replace(location.pathname);
  }

  if (error && !ceremony) return <div>failed to load</div>;
  if (!ceremony || !media) return <div>loading...</div>;
  if (!frames) return <div>loading...</div>;

  const enableChat =
    ceremony.event_status !== 'running' || ceremony.message_enabled_running;

  const frame_url = frames.find((f) => f.id === ceremony.ceremony_frame)?.url;

  const submitNames = async (
    realname: string,
    nickname: string,
    email?: string,
  ) => {
    // TODO: handle error
    await api.post(`/ceremony/${token}/register/`, {
      realname,
      name: nickname,
      email,
      fg: getRandomRgbString(),
      bg: getRandomRgbString(),
    });
    localStorage.setItem(`ceremony:${token}:name`, nickname);
    mutate(`/api/ceremony/${token}/`);
    setName(nickname);
  };

  if (!ceremony.is_manager) {
    // 支払いが完了するまでは表示できない
    if (!ceremony.is_payment_completed) {
      return <div>準備中です。</div>;
    }
    if (ceremony.event_status === 'not_started') {
      return <CeremonyParticipantInfo ceremony={ceremony} />;
    }
    if (
      ceremony.event_status === 'finished' &&
      ceremony.is_archive_enabled !== 'yes'
    ) {
      return <div>このイベントは終了しました。</div>;
    }
  }

  if ((!name || !paid) && !ceremony.is_manager) {
    return (
      <CeremonyParticipantJoin ceremony={ceremony} submitNames={submitNames} />
    );
  }

  return (
    <div
      className="relative mx-auto h-full"
      style={ceremony.ceremony_frame ? { maxWidth: 440 } : {}}
    >
      <LogoBackground />
      {/* Live streaming for background */}
      {ceremony.event_status === 'not_started' && (
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <Video ceremony={ceremony} media={media} />
        </div>
      )}

      {/* UI and live content */}
      <div className="absolute inset-0 flex flex-col">
        <Header ceremony={ceremony} />

        {/* start of main content */}
        <div className="relative h-full w-full flex-1">
          {ceremony.event_status === 'running' && token ? (
            <LiveReceive
              token={token}
              channel={ceremony.id}
              fallback={<Video ceremony={ceremony} media={media} />}
              frameDiv={
                <>
                  {frame_url?.top && (
                    <div className="absolute inset-0 flex flex-col justify-between">
                      <img src={frame_url.top} alt="logo" className="w-full" />
                    </div>
                  )}
                  {frame_url?.bottom && (
                    <div className="absolute inset-0 flex flex-col justify-between">
                      <div />
                      <img
                        src={frame_url.bottom}
                        alt="logo"
                        className="w-full"
                      />
                    </div>
                  )}
                </>
              }
            />
          ) : (
            // if not on live, show video (or image)
            <Video ceremony={ceremony} media={media} />
          )}
          <div className="absolute inset-0 flex flex-col">
            {/*  アナウンス部 */}
            <div className="h-1/2">
              {ceremony.event_status === 'running_before' && (
                <ParticipantAnnouncePre ceremony={ceremony} />
              )}
              {ceremony.event_status === 'running_after' && (
                <ParticipantAnnouncePost ceremony={ceremony} />
              )}
            </div>

            {/*  スペーサー */}
            <div className="flex-1">
              {/*ceremony.event_status === 'running_after' && (
                <ParticipantQuestionnaier
                  name={name}
                  ceremonyId={ceremony.id}
                />
              )*/}
            </div>

            {/*  チャット部 */}
            {enableChat && (
              <div
                className={clsx(
                  ceremony.event_status === 'running' ? 'h-1/3' : 'h-1/2',
                  'overflow-y-auto',
                )}
              >
                {ceremony.logs.slice().map((log) => (
                  <div key={log.timestamp}>
                    <div className="mx-2 my-1">
                      <EventLogItem
                        log={log}
                        participants={ceremony.participants}
                      />
                    </div>
                  </div>
                ))}
                <div ref={scrollToBottomRef} />
              </div>
            )}
            {!enableChat && (
              <div className="bg-black/50 p-2 text-sm text-white">
                <div className="inline-block whitespace-nowrap">
                  お気に召しましたら、 ぜひ
                  {SERVICE_TYPE === 'piano' && 'ピアニストに'}
                  応援のプレゼントを
                </div>
                <div className="inline-block whitespace-nowrap">
                  お願いいたします。
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end of main content */}
        <div className="bg-white/75">
          {enableChat && (
            <ChatSend ceremony={ceremony} participantName={name} />
          )}
          <TabBar ceremony={ceremony} name={name} />
        </div>
      </div>
    </div>
  );
};

const Header: React.FC<{ ceremony: Ceremony }> = ({ ceremony }) => (
  <>
    {ceremony.is_manager && (
      <div className="bg-red-400 py-1 text-center text-white">
        これはプレビューです。
      </div>
    )}
    {ceremony.event_status === 'finished' && (
      <div className="bg-red-400 py-1 text-center text-white">
        このイベントは終了しました。
      </div>
    )}
    <div className="bg-white/50 sm:flex">
      <div className="text-center text-3xl">
        <Logo
          className={clsx(
            'my-1 inline-block',
            SERVICE_TYPE === 'pet' ? 'h-8' : 'h-4',
          )}
        />
        {SERVICE_TYPE === 'pet' && (
          <div className="text-xs">
            本サイトにおける収益金の一部は、
            <br />
            殺処分ゼロ運動に寄付されます。
          </div>
        )}
      </div>
      <div>
        <div className="my-1 text-center font-pop">{ceremony.event_name}</div>
        <div className="my-1 text-center font-pop text-sm">
          {ceremony.event_subtitle}
        </div>
      </div>
      {ceremony.event_status !== 'running' && (
        <div className="my-1 text-center font-pop">
          {ceremony.starts_at &&
            format(parseISO(ceremony.starts_at), 'yyyy/MM/dd HH:mm')}
          ~
        </div>
      )}
      {ceremony.event_status === 'running_before' && (
        <div className="my-1 text-center font-pop">
          {parseISO(ceremony.starts_at!) > new Date() ? (
            <>
              <span className="text-2xl font-bold">
                <Countdown limit={parseISO(ceremony.starts_at!)} />
              </span>
              <br />
              until the event starts
            </>
          ) : (
            'Please wait for the event to start.'
          )}
        </div>
      )}
      <div className="my-2" />
    </div>
  </>
);

const TabBar: React.FC<{ ceremony: Ceremony; name: string }> = ({
  ceremony,
  name,
}) => {
  const isShort = useIsShort();
  const [showParticipant, setShowParticipant] = useState(false);
  const [showGift, setShowGift] = useState(false);

  return (
    <>
      {/* Tab bar */}
      {!isShort ? (
        <div className="mx-4 flex items-center justify-between text-live-ui">
          <button
            type="button"
            className="flex flex-1 flex-col items-center p-1"
            onClick={() => {
              setShowParticipant(true);
            }}
          >
            <i className="bi bi-people text-2xl"></i>
            <span className="text-xs">Participants</span>
          </button>
        </div>
      ) : (
        <div
          className="hidden sm:flex fixed bottom-0 right-0 h-full px-2 flex-col gap-2 justify-end text-live-ui "
          style={{ paddingBottom: 50 }}
        >
          <button
            type="button"
            className="flex flex-col items-center p-1 text-white bg-black/25 bg-opacity-25 rounded-lg"
            onClick={() => {
              setShowGift((v) => !v);
            }}
          >
            <i className="bi bi-gift text-2xl"></i>
            <span className="text-xs">Give a gift</span>
          </button>
          <button
            type="button"
            className="flex flex-col items-center p-1 text-white bg-black/25 bg-opacity-25 rounded-lg"
            onClick={() => {
              setShowParticipant((v) => !v);
            }}
          >
            <i className="bi bi-people text-2xl"></i>
            <span className="text-xs">Participants</span>
          </button>
        </div>
      )}

      {/* Panels */}
      {showParticipant && (
        <ParticipantPanel
          ceremony={ceremony}
          participantName={name}
          onClose={() => {
            setShowParticipant(false);
          }}
        />
      )}
      {showGift && (
        <PaymentPanel
          ceremony={ceremony}
          participantName={name}
          onClose={() => {
            setShowGift(false);
          }}
        />
      )}
    </>
  );
};
